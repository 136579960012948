import React, {useEffect, useState} from 'react';
import {rootPath, loginPath} from '../paths';

import ensoImage from '../misc/enso.jpg';
import './Login.css';

export default function Login(props) {
  const username = 'takashi';
  const [password, setPassword] = useState('');
  const [firstKeyPressed, setFirstKeyPressed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    async function checkLoggedIn() {
      let uri = new URL(process.env.REACT_APP_LOGIN_ENDPOINT);

      let requestOptions = {
          method: 'GET',
          credentials: 'include'
      };

      try {
        let response = await fetch(uri, requestOptions);
        if (response.ok) {
          if (props.location.pathname === loginPath) {
            props.history.replace(rootPath);
          }
          else {
            props.history.replace(props.location.pathname);
          }
        }
        else {
          setLoaded(true);
        }
      }
      catch (e) {
        console.log(e);
      }
    }

    checkLoggedIn();
  }, [props.history, props.location.pathname]);


  useEffect(() => {
    async function submit() {
      let uri = new URL(process.env.REACT_APP_LOGIN_ENDPOINT);

      let requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({username: username, password: password}),
          credentials: 'include'
      };

      try {
        let response = await fetch(uri, requestOptions);

        if (response.ok) {
          props.setLoggedIn(true);

          if (props.location.pathname === loginPath) {
            props.history.replace(rootPath);
          }
          else {
            props.history.replace(props.location.pathname);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }

    if (submitted) {
      submit();
    }
  }, [submitted, password, props]);


  async function handleSubmit(e) {
    e.preventDefault();
    setTimeout(setSubmitted(true), 250);
  }


  function handleKeyPress(e) {
    if (e.target.value === 13) {
      handleSubmit(e);
    }
    else {
      setFirstKeyPressed(true);
      setPassword(e.target.value);
    }
  }


  function renderLogin() {
    return (
      <div id='login' className={submitted ? 'fade-out-activate' : 'fade-out-ready'}>
          <div id='splash-outer' className={loaded ? 'fade-in-activate' : 'fade-in-ready'}>
              <div id='splash-inner' className={firstKeyPressed ? 'shift-up-on-keypress-activate': 'shift-up-on-keypress-ready'}>
                <img src={ensoImage} width='auto' height='auto' alt='login' />
              </div>
          </div>
        
        <div className={firstKeyPressed ? 'passbox-fade-activate' : 'passbox-fade-ready'}>
          <form onSubmit={handleSubmit}>
            <input type='password' id='passbox' value={password} onChange={handleKeyPress} autoFocus />
          </form>
        </div>
      </div>
    );
  }

  return renderLogin();
}