// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../misc/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* HEADER SECTION */\n#header {\n  padding-bottom: 0px;\n  min-height: 550px;\n  height: 100vh;\n}\n\n#admin {position: fixed; z-index:11; top:0px; right:0px; height:20px; width:20px;}\n\n#headerX {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  z-index:0\n}\n\n#headerX img {\n  width: 70%; \n  height: auto;\n}\n\n#headerY {\n  position: relative;\n  width:100%;\n  height: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  text-align:center;\n  z-index:1;\n}\n\n#headerY img {\n  width:70%; \n  height: auto;\n}\n\n#nav {\n  display: none;\n  opacity: 0.001;\n  position: fixed;\n  background-color: transparent;\n  left: 25px;\n  top: 25px;\n  z-index: 100;\n}\n\n\n/* NAV SECTION */\n.navlink {\n  margin-bottom: 0.7em;\n  font: 21px Quaver, Helvetica, Arial;\n  letter-spacing: 0.07rem;\n}\n\n\n\n/* CONTENT SECTION */\n#content-container {\n  /*border: 1px solid #00ff66;*/\n  /*margin-top: 50px;*/\n  /*padding-top: 15px;*/\n  opacity: 0.001;\n\n  z-index:10;\n  min-height: 100vh;\n  overflow: visible;\n  white-space: nowrap;\n}\n\n#content-spacer {\n  /*border: 1px solid #00aaff;*/\n  display: inline-block;\n  width: 10%;\n  min-width: 125px;\n}\n\n\n#content {\n  /*border: 1px solid #ee2266;*/\n  display: inline-block;\n  background-color: transparent;\n  width: 80%;\n  margin-left: auto;\n  margin-right: auto;\n  white-space: normal;\n}", ""]);
// Exports
module.exports = exports;
