import React, {useEffect, useRef} from 'react';

import './About.css';

import {
  githubPath,
  instagramPath,
  linkedinPath
} from '../paths';

import {getEmail} from '../utils';

import paperplaneIcon from '../misc/icons/paperplane-icon.png';
import githubIcon from '../misc/icons/github-icon.svg';
import instagramIcon from '../misc/icons/instagram-icon.svg';
import linkedinIcon from '../misc/icons/linkedin-icon.svg';

import {
  Link
} from 'react-router-dom';

export default function About(props) {
  const aboutRef = useRef();
  const emailRef = useRef();

  useEffect(() => {
    window.scrollTo(0, props.contentRef.current.offsetTop);

    props.setLoaded(true);
  }, [props]);


  function setEmail() {
    if ('current' in emailRef && emailRef.current !== undefined) {
      if (Object.keys(emailRef.current).length !== 0) {
        emailRef.current.href = getEmail();
      }
    }
  }

  return (
    <div id='about' ref={aboutRef}>
      <div id='about-content'>
        <h2>About</h2>
        Think of a secret to tell me, and I'll think of a secret to tell you, and one day we will walk into each other on the street.
        <div id='about-links'>
          <a href='mailto:sleepokay' ref={emailRef} target='_blank'><img src={paperplaneIcon} onClick={setEmail} alt='email'/></a>
          <a href={githubPath} target='_blank'><img src={githubIcon} alt='email'/></a>
          <a href={instagramPath} target='_blank'><img src={instagramIcon} alt='email'/></a>
          <a href={linkedinPath} target='_blank'><img src={linkedinIcon} alt='email'/></a>
        </div>
      </div>
    </div>
  );
};
