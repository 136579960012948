import React, {useEffect, useRef} from 'react';

import './Stories.css';

export default function Stories(props) {
  const emailRef = useRef();

  useEffect(() => {
    window.scrollTo(0, props.contentRef.current.offsetTop);

    props.setLoaded(true);
  }, [props]);

  return (
    <div id='stories'>
      <div id='stories-content'>
        <h2>Stories</h2>
      </div>
    </div>
  );
};
