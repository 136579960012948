import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import Notes from './Notes';
import Stories from './Stories';
import Photos from './Photos';
import Art from './Art';
import Computation from './Computation';
import About from './About';

import loginImage from '../misc/login.png';
import headerImage from '../misc/headerX.jpg';
import headerTransparency from '../misc/headerY.gif';


import './Home.css';

import {
  aboutPath,
  artPath,
  computationPath,
  notesPath,
  notesRootPath,
  photosPath,
  rootPath,
  storiesPath,
  updatePath,
} from '../paths';

import {
  Route,
  Switch,
  Link
} from 'react-router-dom';

export default function Home(props) {
  const [loaded, setLoaded] = useState(false);

  const headerRef = useRef();
  const contentRef = useRef();
  const navRef = useRef();
  const fop = 400.0;  // header fade out position
  const fip = 500.0;  // content fade in position


  useEffect(() => {
    var last_known_scroll_position = 0;
    var ticking = false;

    function handleScroll() {
      last_known_scroll_position = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(function() {
          scrollEffects(last_known_scroll_position);
          ticking = false;
        });
      }
      ticking = true;
    }

    function scrollEffects(scroll_pos) {
      headerRef.current.style.opacity = (1 - scroll_pos/fop);
      contentRef.current.style.opacity = (scroll_pos/fip);
      navRef.current.style.opacity = (scroll_pos/fip);
      
      if (scroll_pos < 25) {
        navRef.current.style.display = 'none';
      }
      else {
        navRef.current.style.display = 'inline';
      }

      if (scroll_pos > fip) {
        navRef.current.style.backgroundColor = '#ffffff';
      }
      else {
        navRef.current.style.backgroundColor = 'transparent';
      }

      if (scroll_pos > window.innerHeight) {
        document.backgroundColor = '#ffffff';
      }
      else {
        document.backgroundColor = '#1b1a20';
      }
    }

    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);


  return (
    <CSSTransition in={loaded} out=''
      classNames='fadein' timeout={{enter: 400}}>
      <div>
        <span id='admin'>
          <Link to={updatePath} id='admin'><img src={loginImage} alt=''/></Link>
        </span>

        <div id='header' ref={headerRef}>
            <div id='headerX'>
              <img src={headerImage} alt='' /></div>
              <div id='headerY'><img src={headerTransparency} alt='' /></div>
        </div>

        <div id='nav' ref={navRef}>
          <div className='navlink'><Link to={notesRootPath}>notes</Link></div>
          <div className='navlink'><Link to={storiesPath}>stories</Link></div>
          <div className='navlink'><Link to={photosPath}>photos</Link></div>
          <div className='navlink'><Link to={artPath}>art</Link></div>
          <div className='navlink'><Link to={computationPath}>computation</Link></div>
          <div className='navlink'><Link to={aboutPath}>about</Link></div>
        </div>

        <div id='content-container' ref={contentRef}>
          <div id='content-spacer'>
          </div>
          <div id='content'>
            <Switch>
              <Route exact path={rootPath}>
                <Notes {...props} setLoaded={setLoaded} contentRef={contentRef}/>
              </Route>

              <Route exact path={notesRootPath}>
                <Notes {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>

              <Route path={notesPath}>
                <Notes {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>

              <Route path={storiesPath}>
                <Stories {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>

              <Route path={photosPath}>
                <Photos {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>

              <Route path={artPath}>
                <Art {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>

              <Route path={computationPath}>
                <Computation {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>

              <Route path={aboutPath}>
                <About {...props} setLoaded={setLoaded} contentRef={contentRef} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}  