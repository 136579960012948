// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./misc/fonts/QuaverSansRegular.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./misc/fonts/MissionGothicRegular.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./misc/fonts/GeorgiaRegular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: Quaver;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: MissionGothic;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: Georgia;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\nhtml {\n  background-color: #1b1a20;\n  overflow-x: hidden;\n}\n\nbody {\n  background-color: #ffffff;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background:#fefefe;\n  font: normal 14px/24px Helvetica, Arial, Verdana, Georgia, Times;\n  color: #333333;\n  line-height:1.5;\n  margin: 0;\n}\n\nli {list-style-type: '\\2013';}\n\na {\n  color: #666666;\n  text-decoration: none;\n  transition: color 0.8s linear;\n}\n\na:visited {\n  color: #666666;\n}\n\na:hover {\n  color: #2a2a2a;\n  transition: color 0s linear;\n}\n\na:active {\n  color: #000000;\n}\n\nh2 {font: normal 30px Quaver, Georgia, Times; color: #333333; line-height:1.7; text-align:left; margin-top: 0px;}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n.fadein-enter {\n  opacity: 0.01;\n}\n\n.fadein-enter.fadein-enter-active {\n  opacity: 1;\n  transition: opacity 400ms linear;\n}\n\n.fadein-enter-done {\n  opacity: 1;\n}", ""]);
// Exports
module.exports = exports;
