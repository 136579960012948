import React, {useEffect, useRef} from 'react';

import './Art.css';

export default function Art(props) {
  const emailRef = useRef();

  useEffect(() => {
    window.scrollTo(0, props.contentRef.current.offsetTop);

    props.setLoaded(true);
  }, [props]);

  return (
    <div id='art'>
      <div id='art-content'>
        <h2>Art</h2>
      </div>
    </div>
  );
};
