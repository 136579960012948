import React, {useEffect, useRef} from 'react';

import './Computation.css';

export default function Computation(props) {
  const emailRef = useRef();

  useEffect(() => {
    window.scrollTo(0, props.contentRef.current.offsetTop);

    props.setLoaded(true);
  }, [props]);

  return (
    <div id='computation'>
      <div id='computation-content'>
        <h2>Computation</h2>
      </div>
    </div>
  );
};
