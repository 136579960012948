import React, {useEffect, useRef, useState} from 'react';
import {
  buildNotesPath,
  notesRootPath,
  rootPath
} from '../paths';
import {addQueryParams, convertAscribedDate, getStatusIcon} from '../utils';

import './Notes.css';

import {
  Link
} from 'react-router-dom';

export default function Notes(props) {
  const [entries, setEntries] = useState({});
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  const [idToOrderMap, setIdToOrderMap] = useState({});
  // const [orderToIdMap, setOrderToIdMap] = useState({});

  const notesRef = useRef();

  useEffect(() => {
    async function fetchEntries() {
      let uri = new URL(process.env.REACT_APP_ENTRIES_ENDPOINT);

      let params = {
        exclude_content: false
      };
      uri = addQueryParams(uri, params);

      let requestOptions = {
          method: 'GET',
          credentials: 'include'
      };

      try {
        let response = await fetch(uri, requestOptions);
        let data = await response.json();

        let tempIdToOrderMap = {}
        // let tempOrderToIdMap = {}
        data.forEach((entry, index) => {
          tempIdToOrderMap[entry.id] = index;
          // tempOrderToIdMap[index] = entry.id;
          }
        );
        setIdToOrderMap(tempIdToOrderMap);
        // setOrderToIdMap(tempOrderToIdMap);
        setEntries(data);
      }
      catch (e) {
        console.log(e);
      }
    }

    fetchEntries();
  }, []);


  useEffect(() => {
    // dynamic data load in background if we are close to edges of current entries array
  }, [currentEntryIndex]);


  useEffect(() => {
    if (Object.keys(entries).length !== 0) {
      let newIndex = 0;

      if ('match' in props && 'params' in props.match && 'entryId' in props.match.params) {
        newIndex = idToOrderMap[parseInt(props.match.params.entryId)];
        window.scrollTo(0, props.contentRef.current.offsetTop);
        if (newIndex === 0 && props.location.pathname === rootPath) {
          props.history.push(rootPath);
        }
      }

      setCurrentEntryIndex(newIndex);

      if (props.location.pathname === notesRootPath) {
        props.history.replace(buildNotesPath(entries[newIndex].id));
      }
    }
    props.setLoaded(true);
  }, [props, idToOrderMap, entries]);


  function renderNotesNav() {
    function newerLink() {
      if (currentEntryIndex > 0) {
        return (
          <span id='newer'>
            <Link to={buildNotesPath(entries[currentEntryIndex-1].id)}>newer →</Link>
          </span>
        );
      }

      return (<span id='newer' />);
    }

    function archive() {
      return (
        <span id='archive'>
          <Link to={rootPath}>archive</Link>
        </span>
      );
    }

    function olderLink() {
      if (currentEntryIndex < Object.keys(entries).length-1) {
        return (
          <span id='older'>
            <Link to={buildNotesPath(entries[currentEntryIndex+1].id)}>← older</Link>
          </span>
        );
      }

      return (<span id='older' />);
    }

    return (
      <div id='notes-nav'>
        {olderLink()}
        {archive()}
        {newerLink()}
      </div>
    );
  }


  function formattedMonth(entry) {
    let month = convertAscribedDate(entry.ascribed_date)[0];

    return (
      <span className='month'>
        <span className='month-first-letter'>{month.slice(0, 1)}</span>{month.slice(1)}
      </span>
    );
  }


  function formattedStatus(entry) {
    if (entry.status === 'public') {
      return null;
    }

    return (<span className='entry-status'>{getStatusIcon(entry.status)}</span>);
  }


  function renderEntry() {
    let entry = entries[currentEntryIndex];

    return (
      <div id='notes-body'>
        <div className='entry-header'>
          {formattedStatus(entry)}
          {formattedMonth(entry)}
          <span className='dateyear'>{convertAscribedDate(entry.ascribed_date)[1]}</span>
          {entry.title ? ' — ' : ''}
          <span className='entry-title'>{entry.title}</span>
        </div>
        <div>
          {entry.content.split('\n').map((item, key) => {
              return <span key={key}>{item}<br/></span>
          })}
        </div>
        <div className='timetag'>
          {entry.ascribed_time}
        </div>
      </div>
    );
  }

  if (Object.keys(entries).length === 0) {
    return null;
  }

  return (
    <div id='notes' ref={notesRef}>
      {renderEntry()}
      {renderNotesNav()}
    </div>
  );
}