import React from 'react';
import draftIcon from './misc/draft.gif';
import protectedIcon from './misc/protected.gif';
import publicIcon from './misc/public.gif';


export const fetchData = async (uri, requestOptions) => {
  try {
    let response = await fetch(uri, requestOptions);
    let data = await response.json();
    return data;
  }
  catch (e) {
    console.log(e);
  }

  return null;
}


export const getCurrentAscribedDate = () => {
  let now = new Date();

  let year = now.getFullYear().toString();
  let month = ('0' + (now.getMonth()+1)).slice(-2);
  let date = now.getDate()

  return year + month + date;
}

export const getCurrentAscribedTime = () => {
  let now = new Date();

  let hours = parseInt(now.getHours());

  let AMPM = 'AM';
  if (hours >= 12) {
    hours -= 12;
    AMPM = 'PM';
  }
  if (hours === 0) {
    hours = 12;
  }

  let minutes = ('0' + now.getMinutes()).slice(-2);

  return hours + ':' + minutes + AMPM;
}


export const convertAscribedDate = (ad) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  let year = ad.slice(0, 4);
  let month = ad.slice(4, 6);
  let date = ad.slice(6, 8);

  return [monthNames[parseInt(month - 1)] + ' ', parseInt(date) + ', ' + year];
}

export const convertAscribedTime = () => {
  let now = new Date();

  let hours = parseInt(now.getHours());

  let AMPM = 'AM';
  if (hours >= 12) {
    hours -= 12;
    AMPM = 'PM';
  }
  if (hours === 0) {
    hours = 12;
  }

  let minutes = ('0' + now.getMinutes()).slice(-2);

  return hours + ':' + minutes + AMPM;
}

export const addQueryParams = (uri, params) => {
  var queryString = '?' + Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&');
  return uri + queryString
}

export const getStatusIcon = (status) => {
  switch (status) {
    case 'draft': return(<img src={draftIcon} alt={status} />);
    case 'protected': return(<img src={protectedIcon} alt={status} />);
    case 'public': return(<img src={publicIcon} alt={status} />);
    default: return null;
  }
}

export const getEmail = () => {
  let username = 'sleepokay';
  let at = '@';
  return 'mailto:' + /*filler*/ username + /*filler@gmail.com*/ at + /*@filler*/ 'gmail.com';
}