import React, {useEffect, useState} from 'react';
import {addQueryParams, getStatusIcon} from '../utils';
import {buildEditPath, rootPath, logoutPath} from '../paths';
import whaleImage from '../misc/whale.png';
import {CSSTransition} from 'react-transition-group';

import {
  Link
} from 'react-router-dom';

import './Update.css';


export default function Update(props) {
  const [entries, setEntries] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchEntries() {
      let uri = new URL(process.env.REACT_APP_ENTRIES_ENDPOINT);

      let params = {
        exclude_content: true
      };
      uri = addQueryParams(uri, params);

      let requestOptions = {
          method: 'GET',
          credentials: 'include'
      };

      try {
        let response = await fetch(uri, requestOptions);
        if ('json' in response) {
          let data = await response.json();
          setEntries(data);
        }
        setLoaded(true);
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchEntries();
  }, []);


  // useEffect(() => {
  //   if (Object.keys(entries).length !== 0) {
  //     setLoaded(true);
  //   }
  // }, [entries]);


  function renderHeader() {
    return (
      <div className='update-header-container'>
        <div className='whale'>
          <Link to={rootPath}>
            <img src={whaleImage}
              alt='whale'
            />
          </Link>
          <div className='nav'>
            <Link to={buildEditPath('new')}><span className='nav_item'>+new</span></Link>
            <Link to={rootPath}><span className='nav_item'>index</span></Link>
            <Link to={logoutPath}><span className='nav_item'>logout</span></Link>
          </div>
        </div>
      </div>
    );
  }

  function renderEntriesTable() {
    if (Object.keys(entries).length === 0) {
      return null;
    }

    let rows = entries.map((entry) =>
        <tr key={entry.id}>
            <td>
              <Link to={buildEditPath(entry.id)}>
                {getStatusIcon(entry.status)}
              </Link>
            </td>
            <td>
              <Link to={buildEditPath(entry.id)}>
                {entry.ascribed_date}
              </Link>
            </td>
            <td>
              <Link to={buildEditPath(entry.id)}>
                {entry.title}
              </Link>
            </td>
        </tr>
    );

    return (
      <table>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  function renderUpdate() {
    return (
      <div className='grid-container'>
        <div className='header'>
          { renderHeader() }
        </div>
        <div className='entries'>
          { renderEntriesTable() }
        </div>
      </div>
    );
  }


  return (
    <CSSTransition in={loaded}
      classNames='updatefade' timeout={{enter: 400}}>
       {renderUpdate()}
    </CSSTransition>
  );
}