import React, {useState, useEffect} from 'react';
import {getCurrentAscribedDate, getCurrentAscribedTime} from '../utils'
import {updatePath} from '../paths';

import './Edit.css';

export default function Edit(props) {
  const [date, setDate] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [time, setTime] = useState('');
  const [status, setStatus] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchEntry() {
      let uri = new URL(process.env.REACT_APP_EDIT_ENDPOINT + '/' + props.match.params.entryId);

      let requestOptions = {
          method: 'GET',
          headers: { 'Accepts': 'application/json' },
          credentials: 'include'
      };

      let entry = null;
      try {
        let response = await fetch(uri, requestOptions);

        if (!response.ok) {
          props.history.push(updatePath);
          return;
        }

        entry = await response.json();
        setDate(entry.ascribed_date);
        setTime(entry.ascribed_time);
        setTitle(entry.title);
        setContent(entry.content);
        setStatus(entry.status);
        setLoaded(true);
      }
      catch (e) {
        console.log(e);
      }
      
    }

    if (props.match.params.entryId === 'new') {
      setDate(getCurrentAscribedDate());
      setTime(getCurrentAscribedTime());
      setStatus('draft');
      setLoaded(true);
    }
    else {
      fetchEntry();
    }
  }, [props.match.params.entryId, props.history]);


  async function handleSubmit(e) {
    e.preventDefault();

    let uri = new URL(process.env.REACT_APP_UPDATE_ENDPOINT);

    // Validate date and time formats
    // [PLACEHOLDER]

    if (status === 'delete') {
      console.log('check delete');
      // return;
    }

    let data = {
      id: props.match.params.entryId,
      ascribed_date: date,
      title: title,
      content: content,
      ascribed_time: time,
      status: status
    }

    let requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
        credentials: 'include'
    };

    try {
      let response = await fetch(uri, requestOptions);
        if (response.ok) {
          props.history.push(updatePath);
        }
      else {
        // Indicate problem and hang on to data
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  // We need this to avoid flash of unstyled content where
  // private is shown as default value before status state is loaded.
  function renderSelect() {
    if (status) {
      return(
        <select name='status' value={status} onChange={e => setStatus(e.target.value)}>
          <option value='private'>private</option>
          <option value='draft'>draft</option>
          <option value='public'>public</option>
          <option value='protected'>protected</option>
          <option value='delete'>delete</option>
        </select>
      )
    }
    return null;
  }


  function renderEditor() {
    if (!loaded) {
      return null;
    }

    return(
      <div id='editor'>
        <form onSubmit={handleSubmit}>
          <div>
            <input type='text' size='9' value={date} onChange={e => setDate(e.target.value)} />
            <input type='text' size='35' value={title} onChange={e => setTitle(e.target.value)} />
          </div>
          <div>
            <textarea rows='40' cols='85' value={content} onChange={e => setContent(e.target.value)} />
          </div>
          <div>
            <input type='text' size='7' value={time} onChange={e => setTime(e.target.value)} />
            { renderSelect() }
          </div>
          <div>
            <input type='submit' value='update' />
          </div>
        </form>
      </div>
    );
  }

  return renderEditor();
}