export const rootPath = '/';

export const loginPath = '/login';
export const updatePath = '/update';
export const editPath = '/edit/:entryId';
export const logoutPath = '/logout';

export const buildEditPath = entryId => `/edit/${entryId}`;


export const notesRootPath = '/notes';
export const notesPath = '/notes/:entryId';
export const storiesPath = '/stories';
export const photosPath = '/photos';
export const artPath = '/art';
export const computationPath = '/computation';
export const aboutPath = '/about';

export const buildNotesPath = entryId => `/notes/${entryId}`;


export const githubPath = 'https://github.com/sleepokay/';
export const instagramPath = 'https://instagram.com/sleepokay_/';
export const linkedinPath = 'https://linkedin.com/in/sleepokay/';