import React, {useEffect, useState} from 'react';
import {rootPath, editPath, loginPath, logoutPath, notesPath, notesRootPath, updatePath} from './paths';
import Login from './containers/Login';
import Update from './containers/Update';
import Edit from './containers/Edit';
import Home from './containers/Home';
import Logout from './containers/Logout';

import './App.css';

import {
  Route,
  Switch,
} from 'react-router-dom';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function checkLoggedIn() {
      let uri = new URL(process.env.REACT_APP_LOGIN_ENDPOINT);

      let requestOptions = {
          method: 'GET',
          credentials: 'include'
      };

      try {
        let response = await fetch(uri, requestOptions);
        if (response.ok) {
          setLoggedIn(true);
        }
      }
      catch (e) {
        console.log(e);
      }
    }

    if (!loggedIn) {
      checkLoggedIn();
    }
  }, [loggedIn]);

  return (
    <div>
      <Switch>
        <Route exact path={rootPath} component={Home} />
        <Route exact path={notesRootPath} component={Home} />
        <Route path={notesPath} component={Home} />

        <Route path={loginPath} render={(props) => (
            <Login {...props} setLoggedIn={setLoggedIn} />
        )}/>
        <Route path={updatePath} render={(props) => (
          loggedIn ? (<Update {...props} />)
           : (<Login {...props} setLoggedIn={setLoggedIn} />)
        )}/>
        <Route path={editPath} render={(props) => (
          loggedIn ? (<Edit {...props} />)
            : (<Login {...props} setLoggedIn={setLoggedIn} />)
        )}/>
        <Route path={logoutPath} render={(props) => (
            <Logout {...props} setLoggedIn={setLoggedIn} />
        )}/>

        <Route component={Home} />
      </Switch>
    </div>
  );
}

export default App;
