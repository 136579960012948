import {useEffect} from 'react';
import {rootPath} from '../paths';

export default function Logout(props) {
  useEffect(() => {
    async function logout() {
      let uri = new URL(process.env.REACT_APP_LOGOUT_ENDPOINT);

      let requestOptions = {
          method: 'GET',
          credentials: 'include'
      };

      try {
        await fetch(uri, requestOptions);
        props.setLoggedIn(false);        
        props.history.replace(rootPath);
      }
      catch (e) {
        console.log(e);
      }
    }

    logout();
  }, [props]);

  return null;
}


